import { AccountAttributes } from '@src/graphql/generated';


enum AccountActionTypes {
  SET_ACCOUNT_ATTRIBUTES = 'SET_ACCOUNT_ATTRIBUTES',
  UPDATE_ACCOUNT_ATTRIBUTES = 'UPDATE_ACCOUNT_ATTRIBUTES'
}


interface SetAccountAttributesAction {
  type: AccountActionTypes.SET_ACCOUNT_ATTRIBUTES;
  payload: AccountAttributes;
}

interface UpdateAccountAttributesAction {
  type: AccountActionTypes.UPDATE_ACCOUNT_ATTRIBUTES;
  payload: Partial<AccountAttributes>;
}
type AccountActions = SetAccountAttributesAction | UpdateAccountAttributesAction;

export function setAccountAttributes(accountState: AccountAttributes): SetAccountAttributesAction
{

  return { type: AccountActionTypes.SET_ACCOUNT_ATTRIBUTES, payload: accountState };

}

export function updateAccountAttributes(partialAccountState: Partial<AccountAttributes>): UpdateAccountAttributesAction
{

  return { type: AccountActionTypes.UPDATE_ACCOUNT_ATTRIBUTES, payload: partialAccountState };

}

const initialState: AccountAttributes = {
  hasCustomer: false,
  hasDepartments: false,
  hasSupplier: false,
};
function accountAttributeReducer(
  state: AccountAttributes = initialState,
  action: AccountActions,
): AccountAttributes
{

  switch (action.type)
  {

    case AccountActionTypes.SET_ACCOUNT_ATTRIBUTES:
      return action.payload;

    case AccountActionTypes.UPDATE_ACCOUNT_ATTRIBUTES:
      return { ...state, ...action.payload };

    default:
      return state;

  }

}

export default accountAttributeReducer;
