import actions from './actions';
import userActions from '../user/actions';

const initialState = {

};

export default function filtersReducer(state = initialState, action)
{

  switch (action.type)
  {

    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case userActions.SET_STATE:
      return action.payload.selectedFilters || state;
    default:
      return state;

  }

}
