import { gql } from '../..';

export const ACCOUNTS_SETTING_FILTER_FIELDS = gql`
  fragment AccountSettingFieldsHOC on AccountSetting {
    marketing {
            allowed
          }
          campaign_management {
            allowed
          }
          publish_item {
            allowed
          }
          dashboard {
            allowed
          }
          planning {
            allowed
          }
          budget {
            allowed
          }
          content_calendar {
            allowed
          }
          meeting {
            allowed
          }
          my_planning {
            allowed
          }
          orders {
            allowed
          }
          project_deadlines {
            allowed
          }
          projects {
            allowed
          }
          time_reporting {
            allowed
          }
          timeline {
            allowed
          }
          production {
            allowed
          }
          assets {
            allowed
          }
          contacts {
            allowed
          }
          emails {
            allowed
          }
          forms {
            allowed
          }
          landing_pages {
            allowed
          }
          segments {
            allowed
          }
          results {
            allowed
          }
          reports {
            allowed
          }
          statistics {
            allowed
          }
          cost_center {
            allowed
          }
          customer {
            allowed
          }
          resource_coverage {
            allowed
          }
          supplier {
            allowed
          }
          worked_time {
            allowed
          }
}`
