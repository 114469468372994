import { PureComponent } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'

@injectIntl
class Error404Page extends PureComponent
{

  render()
  {

    return (
      <div
        style={{
          minHeight: 'calc(100vh - 500px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            maxWidth: '560px',
            backgroundColor: '#fff',
            padding: '80px 30px',
            margin: '100px auto',
            borderRadius: '10px',
            flex: '1',
          }}
        >
          <div
            style={{
              maxWidth: '400px',
              margin: '0 auto',
            }}
          >
            <h1 className="font-size-36 mb-2"><FormattedMessage id="views.404.h1.pt1" /></h1>
            <p className="mb-3"><FormattedMessage id="views.404.p" /></p>
            <h1 className="font-size-80 mb-4 font-weight-bold"><FormattedMessage id="views.404.h1.pt2" /></h1>
            <Link to="/" className="btn">
              &larr; <FormattedMessage id="views.404.link" />
            </Link>
          </div>
        </div>
      </div>
    )

  }

}

export default Error404Page
