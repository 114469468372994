import { DocumentNode, print } from 'graphql';

export const gqlToString = (gql:DocumentNode) => print(gql)

interface IDataWithTypename{
  __typename:string
}
export class DPGraphqlUtil
{

  static omitTypeName<T extends IDataWithTypename>(object:T):Omit<T, '__typename'>
  {

    const newObject:Omit<T, '__typename'> = {} as T;
    Object.keys(object).forEach((key) =>
    {

      if (key !== '__typename')
      {

        newObject[key] = object[key];

      }

    });
    return newObject;

  }

}
