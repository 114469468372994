import { DPApps } from '@src/apps/apps.types';
import { lazy } from 'react';
import SwishRouter from './swish-out/router';
import { AppToInitialize } from './app.util';


if (AppToInitialize === DPApps.p2)
{

  import('./p2/p2.scss')

}
if (AppToInitialize === DPApps.flows)
{

  import('./flows/flows.scss')

}

const LazyComponent = lazy(() => import('../router'));
const P2Router = lazy(() => import('./p2/router'));
const FlowRouter = lazy(() => import('./flows/router'));
const TemplateRouter = lazy(() => import('./template/router'));

const DPRouter = ({ history }) =>
{

  console.log('AppToInitialize', AppToInitialize);

  switch (AppToInitialize)
  {

    case DPApps.p2: {

      return <P2Router history={history} />

    }
    case DPApps.flows: {

      return <FlowRouter history={history} />

    }
    case DPApps.template: {

      return <TemplateRouter history={history} />

    }
    case DPApps.swish_out: {

      return <SwishRouter history={history} />

    }
    default: {

      return <LazyComponent history={history} />

    }

  }


}

export default DPRouter
