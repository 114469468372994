import { GeneralFilter, User, UserFilters } from '../../graphql/generated'

export type filterKeys = Exclude<keyof GeneralFilter | keyof UserFilters, '__typename'>;
export default function getUserFilter(filters: User['selectedFilters'], filter: filterKeys, _db?: string)
{

  if (!_db) return filters?.general?.[filter]
  const accountFilter = filters?.accountFilters?.find(acc => acc._db === _db);
  const value = accountFilter?.filters?.[filter];
  return value

}
