import { withRouter, Redirect } from 'react-router-dom'
import { useDPReduxAppSelector } from '@src/redux';
import DPLayoutUtils from '@src/utils/DPLayoutUtils';
import Loader from '@src/components/Layout/Loader';
import LoginLayout from '@src/layouts/Login';
import DPHelmet from '@src/components/Layout/DPHelmet';
import React, { PropsWithChildren } from 'react';

export type DPIndexLayoutProps = {
  defaultPath: string;
};

const DPLayout = (props:PropsWithChildren<DPIndexLayoutProps>) =>
{

  const { children, defaultPath } = props;

  const publicPaths = ['/p/', '/o/', '/pr', '/to', '/as', '/u/'];
  const isPublicPath = publicPaths.some(publicPath => window.location.pathname.includes(publicPath));

  const user: any = useDPReduxAppSelector(state => state.user);
  const isUserAuthorized = user.authorized;
  const isUserLoading = user.loading;
  const isLoginLayout = DPLayoutUtils.getLayoutName(window.location.pathname) === 'login';


  const cacheCurrentPath = async (path) =>
  {

    await global.localStorage.setItem('loadedPath', path);

  }

  const getCachePath = () =>
  {

    const path = global.localStorage.getItem('loadedPath')
    global.localStorage.removeItem('loadedPath')
    return path

  }


  const getBootstrappedLayout = () =>
  {

    if (isPublicPath)
    {

      return <div>{children}</div>

    }


    if (isUserLoading && !isUserAuthorized && !isLoginLayout)
    {

      return <Loader fullScreen />;

    }
    if (!isLoginLayout && !isUserAuthorized)
    {

      // eslint-disable-next-line react/no-this-in-sfc
      cacheCurrentPath(window.location.pathname)
      return <Redirect to="/user/login" />;

    }
    if (isLoginLayout && isUserAuthorized)
    {

      // eslint-disable-next-line react/no-this-in-sfc
      const loadedPath = getCachePath()
      if (loadedPath && loadedPath !== '/user/login' && loadedPath !== '/')
      {

        return <Redirect to={loadedPath} />;

      }
      return <Redirect to={defaultPath} />;

    }
    if (isLoginLayout)
    {

      return (
        <LoginLayout>
          {children}
        </LoginLayout>
      )

    }
    return (
      <div>
        {children}
      </div>
    )

  }

  return (
    <>
      <DPHelmet path={window.location.pathname} />
      {getBootstrappedLayout()}
    </>
  )

}

const DPIndexLayout = withRouter(DPLayout) as React.ComponentType<PropsWithChildren<DPIndexLayoutProps>>;

export default DPIndexLayout;
