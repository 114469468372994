const limit = 20;
const formResourceLimit = 100;
const PublishItemPaginationLimit = 5;
const responsibleAvatarSize = 50
// eslint-disable-next-line import/no-mutable-exports
let serverLink = `${window.location.origin}/graphql`;
// eslint-disable-next-line import/no-mutable-exports
let serverOrigin = `${window.location.origin}`;
const redirectUri = `${window.location.origin}/api`;

if (window.location.hostname.includes('local'))
{

  serverLink = 'http://localhost:4000/graphql';
  serverOrigin = 'http://localhost:4000';

}


// eslint-disable-next-line import/no-mutable-exports
let googleClientId = '177801232776-g9k4f9lloksnepdiaqauk118vqno7mhf.apps.googleusercontent.com'
if (window.location.origin.includes('localhost'))
{

  googleClientId = '819332635177-dld302ito03p5o1dhtdbe86c4mo8dh19.apps.googleusercontent.com'

}

export {
  googleClientId,
  limit,
  formResourceLimit,
  PublishItemPaginationLimit,
  serverLink,
  redirectUri,
  serverOrigin,
  responsibleAvatarSize,
};
