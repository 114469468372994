import { Account } from '../../graphql/generated'

const initialState:Account = {
  _id: '',
  _db: '',
}

export default function accountReducer(state = initialState, action)
{

  switch (action.type)
  {

    case 'SELECTED_ACCOUNT':
      return { ...action.data }
    default:
      return state

  }

}
