import { createContext } from 'react';
import { DPBaseProps } from '@components/core/_types/DPBaseProps';
import { IntlShape, useIntl } from 'react-intl';

// redefine the context type to include a specific updater function type
// ----------------------------------------------------------------------------------
export type DPLangContextType = {
  intl: IntlShape;
};

// default context state
// ----------------------------------------------------------------------------------
const defaultLangContextState: DPLangContextType = {
  intl: {} as IntlShape,
};

// create a context with a default value
// ----------------------------------------------------------------------------------
export const DPLangContext = createContext<DPLangContextType>(defaultLangContextState);

// log provider component
// ----------------------------------------------------------------------------------
export const DPLangProvider = ({ children }: DPBaseProps) => {
  const intl = useIntl();

  return <DPLangContext.Provider value={{ intl }}>{children}</DPLangContext.Provider>;
};
