const initialState = {
  reference: null,
}

export default (state = initialState, { type, payload }) =>
{

  switch (type)
  {

    case 'SET_GLOBAL_ELEMENTS_DATA':
      return { ...state, ...payload };

    default:
      return state;

  }

};
